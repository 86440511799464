import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { PAYMENT_MODES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const Payment = () => {
  const { push } = useHistory();
  const { exam, department } = useParams();
  const [payments, setPayments] = useState([]);

  const getAllPayment = async () => {
    const res = await instance.get(
      `${endpoints.getAllPayments}?exam=${exam}&department=${department}`
    );

    setPayments(res.data);
  };

  const deletePayment = async (id) => {
    try {
      await instance.delete(`${endpoints.deletePayment}?_id=${id}`);
      setPayments((prev) => prev.filter((filt) => filt._id !== id));
      showToast({
        type: "success",
        message: "Successfully Deleted the payment",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  useEffect(() => {
    getAllPayment();
  }, []);

  return (
    <div className="text-center container ">
      <h2 className="space-heading text-center mb-5">Payments</h2>

      <div className="flex justify-end mb-5">
        <Button onClick={() => push("/create-payment")} className="w-25">
          Add New
        </Button>
      </div>

      {payments && payments?.length ? (
        <table id="reviews">
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Mode</th>
            <th>Expiry Date</th>
            <th>Actual Price</th>
            <th>Discount</th>
            <th>Actions</th>
          </tr>

          {payments?.map((payment) => (
            <tr>
              <td>{payment.title}</td>
              <td>{payment.description}</td>
              <td>{PAYMENT_MODES[payment.mode]}</td>
              <td>{payment.expiryDate && payment.expiryDate.slice(0, 10)}</td>
              <td>{payment.actualPrice}</td>
              <td>{payment.discount}</td>

              <td className="text-center">
                <AiFillDelete
                  onClick={() => deletePayment(payment._id)}
                  className="c-pointer"
                  size={20}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : null}
    </div>
  );
};

export default Payment;
