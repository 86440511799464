import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Button, Submit } from '../../components/buttons';
import { Input, Select, Time } from '../../components/inputs';
import { Section } from '../../components/Section';
import { endpoints } from '../../constants/Endpoints';
import useData from '../../hooks/useData';
import { authUser, selectedDepartmentObject } from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { Type } from '../../utilities/constants';
import { showToast } from '../../utilities/toast';
import { checkError } from '../../utilities/error';
import CommonInput from '../../components/CommonInput';
import { AWS_DIRECTORIES, LANGUAGES } from '../../constants/Common';
import DatePicker from '../../components/DatePicker';
import ReactSelect from 'react-select';

const AddTestSeries = () => {
  const { department, exam } = useParams();
  const [toRenderSection, setToRenderSection] = useState([]);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const [marks, setMarks] = useState('');
  const [time, setTime] = useState(0);
  const [mode, setMode] = useState('');
  const [selectedDate, setDate] = useState();
  const [sectionChoice, setSectionChoice] = useState();
  const user = useRecoilValue(authUser);
  const [name, setName] = useState({ [language.value]: '' });
  const [instructions, setInstructions] = useState({ [language.value]: '' });
  const [description, setDescription] = useState({ [language.value]: '' });

  const [subjects, setSubjects] = useState([]);
  const { getSubjectByDepartment } = useData();


  React.useEffect(() => {
    const fetchSubjects = async () => {
      const subjects = await getSubjectByDepartment(department);
      setSubjects(subjects);
    };

    if (mode === 'subject') {
      fetchSubjects();
    }
  }, [department, getSubjectByDepartment, mode]);

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) {
          temp = true;
        }
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: '',
      questions: [],
      sectionId: 'section' + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    let allSubjects = [];
    for (let i = 0; i <= subjects.length; i++) {
      if (formData.get('checkbox' + i)) {
        allSubjects.push(formData.get('checkbox' + i));
      }
    }

    if (mode === 'subject' && !allSubjects.length) {
      showToast({
        type: 'error',
        message: 'Please select atleast one subject',
      });

      return;
    }

    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      name,
      postedBy: user.userId,
      time,
      mode,
      instructions,
      description,
      marks,
      department,
      choice: sectionChoice,
      exam,
      sections: sectionsClone,
      releaseDate: selectedDate?.getTime(),
      subjects: allSubjects,
    };

    try {
      await instance.post(endpoints.addTestSeries, data);
      showToast({
        type: 'success',
        message: 'Successfully added Test series..',
      });

      setMarks('');
      setMode('');
      setTime(0);
      setName({ [language.value]: '' });
      setInstructions({ [language.value]: '' });
      setDescription({ [language.value]: '' })
      setToRenderSection([]);
    } catch (err) {
      showToast({
        type: 'error',
        message: err.response.data.message,
      });
    }
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };


  return (
    <div className='container mb-5 d-flex flex-column justify-content-center'>
      <div className='d-flex justify-content-center '>
        <h3 className='mb-5 bolder space-heading'> Create Test Series</h3>
      </div>
      <form className='horizontal' onSubmit={onSubmit}>
        <div className='flex gap-x-8 gap-y-1 items-center flex-wrap px-3 mb-3 bo'>
          <Input
            className='!w-56'
            id='name'
            label='Name'
            value={name[language?.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <Input
            className='!w-52'
            value={marks}
            label='Total marks'
            onChange={setMarks}
          />

          <Time value={time} onChange={setTime} label='Time' />

          <Select
            className='!w-48'
            value={mode}
            onChange={setMode}
            label='Type'>
            <option value='full'>Full Length</option>
            <option value='subject'>Subject Wise</option>
          </Select>

          <Input
            className='!w-56'
            name='choice'
            label='Choice'
            type='number'
            value={sectionChoice}
            onChange={(e) => (e > -1 ? setSectionChoice(e) : null)}
          />

          <DatePicker
            className='-mt-3'
            date={selectedDate}
            label='Release Date'
            onChange={setDate}
          />

          <div className='-mt-1'>
            <h6 className='mb-1'>Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>
        </div>

        <hr className='!mt-6 !mb-6' />

        {mode === 'subject' ? (
          <div className='select-subject-section'>
            <h5 className='subject-heading'>Select Subjects:</h5>
            <div className='test-series-subjects'>
              {subjects.map((subj, index) => {
                return (
                  <div className='test-series-subject-input' key={index}>
                    <div className='form-check'>
                      <label
                        htmlFor={'checkbox' + (index + 1)}
                        className='form-check-label'>
                        {subj.title}
                      </label>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={subj._id}
                        id={'checkbox' + (index + 1)}
                        name={'checkbox' + (index + 1)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <div className='mb-4 mx-4'>
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.TEST_SERIES}
            label='General Instructions'
            value={instructions?.[language.value]}
            onChange={setInstructions}
          />
        </div>

        <div className='mb-3 mx-4'>
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.TEST_SERIES}
            label='Description'
            value={description[language.value]}
            onChange={setDescription}
          />
        </div>

        {toRenderSection.map((section, index) => {
          return (
            <Section
              language={language}
              key={index + section.sectionId}
              type={Type.SERIES}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}

        <div className='d-flex justify-content-start mt-3 '>
          <Button width='25' onClick={handleSection}>
            Add Section
          </Button>
        </div>

        <div className='d-flex justify-content-end mt-3'>
          <Submit
            width='25'
            disabled={
              !name[language.value] ||
              !toRenderSection.length ||
              time === 0 ||
              !marks ||
              !instructions[language.value] ||
              !mode ||
              !selectedDate
            }
          />
        </div>
      </form>
    </div>
  );
};

export default AddTestSeries;
