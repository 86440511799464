export const MODULES = {
  DPP: "DPP",
  TEST_SERIES: "TEST_SERIES",
  PREVIOUS_YEAR_PAPER: "PREVIOUS_YEAR_PAPER",
  WORK_BOOK: "WORK_BOOK",
  ASSIGNMENT: "ASSIGNMENT",
  NOTIFICATION: "NOTIFICATION",
  SYLLABUS: "SYLLABUS",
  YEAR_CUT_OFF: "YEAR_CUT_OFF",
  EXAM_ANALYSIS: "EXAM_ANALYSIS",
  GENERAL_VIDEO: "GENERAL_VIDEO",
};

export const MODES = {
  FULL_LENGTH: "full_length",
  SUBJECT_WISE: "subject_wise",
};

export const LANGUAGES = {
  english: "en",
  urdu: "ur",
  hindi: "hn",
  gujrati: "gu",
  panjabi: "pa",
};

export class AWS_DIRECTORIES {
  static DPP = "dpp";
  static WORK_BOOK = "workbook";

  static PREVIOUS_YEAR_PAPER = "previousYearPaper";
  static TEST_SERIES = "testSeries";
  static ASSIGNMENT = "assignment";
  static NOTIFICATIONS = "notifications";
  static SYLLABUS = "syllaus";
  static YEAR_CUTOFF = "yearCutoff";
  static EXAM_ANALYSIS = "examAnalysis";
  static REVIEWS = "reviews";

  static QUESTIONS = "";

  static EXAMS = "exams";
}

export class PAYMENT_MODES {
  static "previous-paper-1-year" = "Previous Paper - 1 Year";
  static "previous-paper-2-year" = "Previous Paper - 2 Year";
  static "test-series" = "Test Series";
  static "test-series+previous-paper-1-year" =
    "Test Series + Previous Year - 1 Year";
  static "test-series+previous-paper-2-year" =
    "Test Series + Previous Year - 2 Years";
}
