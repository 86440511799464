import "./style.scss";
import ReactSelect from 'react-select';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Input } from "../../components/inputs";
import { showToast } from "../../utilities/toast";
import { Button } from "../../components/buttons";
import { endpoints } from "../../constants/Endpoints";
import { useHistory, useParams } from "react-router-dom";
import { selectedDepartmentObject } from '../../providers';
import { instance } from "../../services/https/inceptors";
import CommonInput from "../../components/CommonInput/index";
import CommonResult from "../../components/CommonResult/index";
import { AWS_DIRECTORIES, LANGUAGES } from '../../constants/Common';

const Reviews = () => {
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });
  const [name, setName] = useState({ [language.value]: '' });
  const [description, setDescription] = useState({ [language.value]: '' });

  const [isDisable, setIsDisable] = useState(true);
  const [index, setIndex] = useState(-1);
  const [_id, set_id] = useState("");
  const [review, setReview] = useState([]);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const { department, exam } = useParams();
  const { push } = useHistory();

  const getReview = async () => {
    const res = await instance.get(
      `${endpoints.getReview}?exam=${exam}&department=${department}`
    );
    setPrevious(res.data);
  };

  const setPrevious = (data) => {
    setName(data.name ?? {});
    setDescription(data.description ?? {});
    set_id(data._id);
    setReview(data.reviews);
  };

  const deleteReview = async (id) => {
    try {
      await instance.delete(
        `${endpoints.deleteReview}?reviewId=${id}&_id=${_id}`
      );
      const filteredReviews = review.filter((rev) => rev._id !== id);
      setReview(filteredReviews);
      showToast({ type: "success", message: "Successfully Delete Review!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  useEffect(() => {
    getReview();
  }, []);

  const saveTitle = async () => {
    try {
      await instance.post(endpoints.addReviewTitle, {
        _id,
        name,
        department,
        exam,
      });
      setIndex(-1);
      setIsDisable(true);
      showToast({ type: "success", message: "Successfully Save Title" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const saveDescription = async () => {
    try {
      await instance.post(endpoints.addReviewDescription, {
        _id,
        description,
        department,
        exam,
      });
      setIndex(-1);
      setIsDisable(true);
      showToast({ type: "success", message: "Successfully Save Description" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className="container">
      <h2 className="text-center space-heading">Reviews</h2>
      <div className='mb-4 mt-5 px-3 flex flex-col items-center'>
        <div>
          <h6 className='mb-2 font-semibold'>Select Language</h6>
          <ReactSelect
            onChange={onLanguageChange}
            options={selectedDepart?.languages?.map((lang) => {
              const label = Object.keys(LANGUAGES).find(
                (key) => LANGUAGES[key] === lang
              );
              return {
                value: lang,
                label: label.replace(/^./, label[0].toUpperCase()),
              };
            })}
            value={language}
            placeholder='Select Language'
            className='w-72'
          />
        </div>
      </div>

      <div className="review">
        <div className='flex flex-col justify-center items-center'>
          <div className="flex items-center gap-x-7">
            <Input
              className='!w-56'
              id='name'
              label='Title'
              value={name?.[language?.value]}
              disabled={!(index === 0 && isDisable)}
              onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
            />

            {!(index === 0 && isDisable) ? (
              <Button
                className='mt-2'
                onClick={() => {
                  setIndex(0);
                  setIsDisable(true);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button onClick={saveTitle}>Save</Button>
            )}
          </div>
        </div>

        <div className="review-description mt-5">
          {!(index === 1 && isDisable) ? (
            <div className="description-review">
              <h6 className='text-center mb-2'>Description</h6>
              <textarea
                disabled={!(index === 1 && isDisable)}
                rows="5"
                value={description?.[language.value]}
              />
              <Button
                disabled={!name?.[language.value]}
                onClick={() => {
                  setIndex(1);
                  setIsDisable(true);
                }}
              >
                Edit
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <div className="description-edit">
                <div className="input">
                  <CommonInput
                    directory={AWS_DIRECTORIES.REVIEWS}
                    language={language.value}
                    label="Description"
                    value={description?.[language.value]}
                    onChange={setDescription}
                  />
                </div>

                <div className="result">
                  <h6>Description Result</h6>
                  <div className="result-border">
                    <CommonResult language={language.value} disabled text={description} />
                  </div>
                </div>
              </div>
              <Button onClick={saveDescription}>Save</Button>
            </div>
          )}
        </div>

        {name?.[language.value] || description?.[language.value] ? (
          <div className="reviews mt-5 mb-5 text-center">
            <h4 className="space-heading text-center mb-4">Reviews</h4>

            {review?.length ? (
              <table id="reviews">
                <tr>
                  <th>Username</th>
                  <th>Designation</th>
                  <th>Comments</th>
                  <th>Photo</th>
                  <th>Gender</th>
                  <th>Actions</th>
                </tr>

                {review?.map((peopleReview) => (
                  <tr>
                    <td>{peopleReview.username}</td>
                    <td>{peopleReview.designation}</td>
                    <td style={{ overflowWrap: "anywhere" }}>
                      {peopleReview.comment}
                    </td>
                    <td>{peopleReview.photo}</td>
                    <td>{peopleReview.gender}</td>
                    <td className="text-center">
                      <AiFillDelete
                        onClick={() => deleteReview(peopleReview._id)}
                        className="c-pointer"
                        size={20}
                      />
                    </td>
                  </tr>
                ))}
              </table>
            ) : null}

            <Button
              className="mt-5 mb-5"
              onClick={() => push(`/create-review/${_id}`)}
            >
              Add Reviews
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Reviews;
