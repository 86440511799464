import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { AWS_DIRECTORIES } from '../constants/Common';
import { Button, Equation } from './buttons';
import { Image } from './inputs';

const Question = ({ value, onChange, label, language, returnEvent = false }) => {
  const ref = useRef();

  function addImg(imgSrc) {
    if (returnEvent) {
      onChange(value + `<center><img src="${imgSrc}" height="150px" alt="question_img"/></center>`);
    } else {
      onChange((prev) => ({
        ...prev,
        [language]:
          prev[language] +
          `<center><img src="${imgSrc}" height="150px" alt="question_img"/></center>`,
      }));
    }
  }

  function onDash() {
    const dash =
      value?.length === 0 || value.endsWith(' ') ? '/dash ' : ' /dash ';


    if (returnEvent) {
      onChange(dash)
    }
    else {
      onChange((prev) => ({
        ...prev,
        [language]: prev[language] + dash,
      }));
    }
    ref.current.focus();
  }

  function onEquation(text) {
    const equation =
      value?.length === 0 || value.endsWith(' ') ? text : ' ' + text;

    if (returnEvent) {
      onChange(equation)
    } else {
      onChange((prev) => ({
        ...prev,
        [language]: prev[language] + equation,
      }));
    }
  }

  return (
    <div className='mb-3'>
      <label className='form-label font-semibold'>{label}</label>

      <textarea
        rows='5'
        className='form-control'
        ref={ref}
        value={value}
        onChange={(event) => returnEvent ? onChange(event.target.value) : onChange((prev) => ({ ...prev, [language]: event.target.value }))} />

      <div className='d-flex mt-3'>
        <Button className='me-3 square' onClick={onDash}>
          &#x0005F;
        </Button>

        <Equation inputRef={ref} onEntered={onEquation} />

        <Image
          directory={AWS_DIRECTORIES.QUESTIONS}
          className='ms-3'
          onUpload={(value_) => addImg(value_)}
        />
      </div>
    </div>
  );
};

const Length = ({ onChange, choices, language }) => {
  function addMarks() {
    const clone = [...choices];
    const choicesFormat = { ...Choices.NODES };
    choicesFormat.text = { [language]: '' };
    choicesFormat.id = choices.length + 1;
    clone.push(choicesFormat);
    onChange(clone);
  }

  function minusMarks() {
    if (choices?.length > 1) {
      const clone = [...choices];
      clone.splice(clone?.length - 1, 1);
      onChange(clone);
    }
  }

  return (
    <div className='mb-3'>
      <label className='form-label'>Number of Choices</label>

      <div className='d-flex'>
        <Button
          className='square'
          disabled={choices?.length === 0}
          onClick={minusMarks}>
          -
        </Button>

        <div className='form-control mx-3 text-center'>{choices?.length}</div>

        <Button
          className='square'
          disabled={choices?.length === 7}
          onClick={addMarks}>
          +
        </Button>
      </div>
    </div>
  );
};

Length.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};
const Marks = ({ value, onChange, name }) => {
  return (
    <div className='mb-3'>
      <label className='form-label'>{name ? name : 'Marks'}</label>

      <div className='d-flex'>
        <input
          type='text'
          className='form-control'
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </div>
  );
};

Marks.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

const Answer = ({ value, onChange, children, language }) => {
  const ref = useRef();

  function onEquation(text) {
    const equation =
      value?.length === 0 || value.endsWith(' ') ? text : ' ' + text;

    onChange((prev) => ({
      ...prev,
      [language]: prev[language] + equation,
    }));
  }

  return (
    <div className='d-flex mb-3'>
      <div className='col'>
        <input
          placeholder='Answer'
          type='text'
          className='form-control'
          ref={ref}
          value={value}
          onChange={(event) =>
            onChange((prev) => ({ ...prev, [language]: event.target.value }))
          }
        />
      </div>

      <div className='ms-3 d-flex'>
        <Equation inputRef={ref} onEntered={onEquation} />
        {children}
      </div>
    </div>
  );
};

Answer.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

/**
 * Single choice
 */

const Choice = ({ choice, onChange, mode, language }) => {
  // const language = useRecoilValue(selectedLanguage);
  const ref = useRef();

  function addImg(value_) {
    onChange({
      ...choice,
      text: {
        ...choice.text,
        [language]:
          choice.text[language] +
          `<img src="${value_}" height="150px" alt="choice_img"/>`,
      },
    });
  }

  function onEquation(value) {
    const equation =
      choice.text[language]?.length === 0 || choice.text[language].endsWith(' ')
        ? value
        : ' ' + value;

    onChange({
      ...choice,
      text: { ...choice.text, [language]: choice.text[language] + equation },
    });
  }

  return (
    <div className='d-flex mb-3'>
      <div className='col'>
        <input
          type='text'
          className='form-control'
          ref={ref}
          value={choice?.text[language]}
          onChange={(event) =>
            onChange({
              ...choice,
              text: { ...choice.text, [language]: event.target.value },
            })
          }
        />
      </div>

      <div className='ms-3 d-flex'>
        <Equation inputRef={ref} onEntered={onEquation} />

        <Image
          directory={AWS_DIRECTORIES.QUESTIONS}
          className='mx-3'
          onUpload={(value_) => addImg(value_)}
        />
        <Button
          className='square'
          color={choice.answer ? 'success_' : 'primary_'}
          disabled={!choice.text && !choice.image}
          onClick={() => onChange({ ...choice, answer: !choice.answer })}>
          &#x02713;
        </Button>
      </div>
    </div>
  );
};

Choice.propTypes = {
  mode: PropTypes.string,
  choice: PropTypes.object,
  onChange: PropTypes.func,
};

/**
 * Choices to question
 */

const Choices = ({ values, onChange, mode, language }) => {
  const onEdit = (value) => {
    console.log('value ===> ', value);
    const choicesClone = [...values];

    const data = choicesClone?.map((choice) => {
      if (choice.id === value.id) {
        return value;
      }
      return choice;
    });

    onChange(data);
  };

  return values.map((choice, index) => (
    <Choice
      key={index}
      choice={choice}
      mode={mode}
      onChange={onEdit}
      language={language}
    />
  ));
};

Choices.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  mode: PropTypes.string,
};

Choices.NODES = { text: '', image: '', answer: false, id: 0 };

/**
 * Solution to question
 */

const Solution = ({ value, onChange, language }) => {
  const ref = useRef();

  function onEdit(value) {
    onChange((previous) => {
      previous.text[language] = value;
      return { ...previous };
    });
  }

  function addImg(value_) {
    let valueClone = { ...value };

    valueClone.text[language] =
      valueClone.text[language] +
      `<center><img src="${value_}" height="150px" alt="img"/></center>`;

    onChange(valueClone);
  }

  function onEquation(text) {
    const prev = value.text[language];
    const equation =
      prev?.length === 0 || prev.endsWith(' ') ? text : ' ' + text;

    let valueClone = { ...value };
    valueClone.text[language] = valueClone.text[language] + equation;
    onChange(valueClone);
  }

  return (
    <div className='mb-3'>
      <label htmlFor='solution' className='form-label'>
        Solution
      </label>

      <textarea
        id='solution'
        rows='5'
        className='form-control'
        ref={ref}
        value={value.text[language] ? value.text[language] : ''}
        onChange={(event) => onEdit(event.target.value)}></textarea>

      <div className='d-flex mt-3 gap-2'>
        <Image
          directory={AWS_DIRECTORIES.QUESTIONS}
          className='ms-3'
          onUpload={(value_) => addImg(value_)}
        />
        <Equation inputRef={ref} onEntered={onEquation} />
      </div>
    </div>
  );
};

Solution.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

Solution.NODES = { text: '' };

/**
 * Question mode: Answer or Choices
 */

const Mode = ({ value, onChange }) => (
  <div className='mb-3'>
    <label className='form-label'>Mode</label>

    <div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='mode'
          id='answer'
          checked={value === Mode.answer}
          onChange={() => onChange(Mode.answer)}
        />

        <label className='form-check-label' htmlFor='answer'>
          Answer
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='mode'
          id='options'
          checked={value === Mode.mcq}
          onChange={() => onChange(Mode.mcq)}
        />

        <label className='form-check-label' htmlFor='options'>
          MCQ
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='mode'
          id='options'
          checked={value === Mode.msq}
          onChange={() => onChange(Mode.msq)}
        />

        <label className='form-check-label' htmlFor='options'>
          MSQ
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='mode'
          id='options'
          checked={value === Mode.subjective}
          onChange={() => onChange(Mode.subjective)}
        />

        <label className='form-check-label' htmlFor='options'>
          Subjective
        </label>
      </div>
    </div>
  </div>
);

Mode.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Mode.answer = 'answer';
Mode.mcq = 'mcq';
Mode.msq = 'msq';
Mode.subjective = 'subjective';

export { Question, Marks, Answer, Choices, Solution, Mode, Length };
