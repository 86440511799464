import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Actions from "../../components/Action/index";
import { Button } from "../../components/buttons";
import EmptyIcon from "../../components/EmptyIcon/index";
import { Select } from "../../components/inputs";
import { LANGUAGES, MODULES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedDepartmentObject, selectedLanguage } from '../../providers';
import ReactSelect from 'react-select';

const Workbook = () => {
  const { push } = useHistory();
  const [subject, setSubject] = useState();
  const { department, exam } = useParams();
  const [workBooks, setWorkBooks] = useState();
  const [subjects, setSubjects] = useState([]);

  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);
  const { getSubjectByDepartment } = useData();

  const getAllWorkbooks = async () => {
    const res = await instance.get(
      `${endpoints.getAllWorkbooks}?exam=${exam}&department=${department}`
    );
    setWorkBooks(res.data);
  };

  const getSubjects = async () => {
    const res = await getSubjectByDepartment(department);
    setSubjects(res);
  };

  const onSearch = async () => {
    const res = await instance.get(
      `${endpoints.getWorkbookBySubject}?subject=${subject}&exam=${exam}&department=${department}`
    );
    setWorkBooks(res.data);
  };

  useEffect(() => {
    getAllWorkbooks();
    getSubjects();
  }, []);

  return (
    <>
      <div className="container mb-5">
        <h2 className="mt-3 space-heading text-center mb-3">WorkBook</h2>

        <div className="d-flex flex-row justify-content-between mx-5 mt-3 align-items-center border p-3 shadow rounded">
          <div className="flex items-center gap-x-5">
            <Select
              onChange={setSubject}
              value={subject}
              label="Select Subject"
            >
              {subjects.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.title}
                </option>
              ))}
            </Select>

            <div className='-mt-3'>
              <h6 className='mb-1 font-semibold'>Select Language</h6>
              <ReactSelect
                onChange={setCurrentLanguage}
                options={selectedDepart?.languages?.map((lang) => {
                  const label = Object.keys(LANGUAGES).find(
                    (key) => LANGUAGES[key] === lang
                  );
                  return {
                    value: lang,
                    label: label.replace(/^./, label[0].toUpperCase()),
                  };
                })}
                value={currentLanguage}
                placeholder='Select Language'
                className='w-52'
              />
            </div>
          </div>

          <div className="d-flex w-100 gap-3 justify-content-end">
            <Button color="success" width="20" onClick={onSearch}>
              Search
            </Button>
            <Button
              color="success"
              width="25"
              onClick={() => push(`/add-workbook/${department}/${exam}`)}
            >
              Create New Workbook
            </Button>
          </div>
        </div>

        <div className="mt-5 m-auto w-50">
          {workBooks?.length ? (
            <Actions
              language={currentLanguage}
              type={MODULES.WORK_BOOK}
              data={workBooks}
              setData={setWorkBooks}
            />
          ) : (
            <EmptyIcon />
          )}
        </div>
      </div>
    </>
  );
};

export default Workbook;
