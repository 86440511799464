import { atom } from "recoil";
import { MODES } from "../constants/Common";
import { LOCAL_CONSTANT } from "../constants/LocalConstants";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const authUser = atom({
  key: "user",
  default: null,
  effects: [localStorageEffect(LOCAL_CONSTANT.SET2_SCORE_USER)],
});

export const exam = atom({
  key: "exam",
  default: "",
  effects: [localStorageEffect(LOCAL_CONSTANT.SELECTED_EXAM)],
});

export const globalSelectedExamLabel = atom({
  key: "globalSelectedExamLabel",
  default: null,
  effects: [localStorageEffect(LOCAL_CONSTANT.GLOBAL_SELECTED_EXAM_LABEL)],
});

export const globalSelectedDepartLabel = atom({
  key: "globalSelectedDepartLabel",
  default: [],
  effects: [localStorageEffect(LOCAL_CONSTANT.GLOBAL_SELECTED_DEPART_LABEL)],
});

export const department = atom({
  key: "department",
  default: "",
  effects: [localStorageEffect(LOCAL_CONSTANT.SELECTED_DEPARTMENT)],
});

export const selectedDepartmentObject = atom({
  key: LOCAL_CONSTANT.SELECTED_DEPARTMENT_OBJECT,
  default: undefined,
  effects: [localStorageEffect(LOCAL_CONSTANT.SELECTED_DEPARTMENT_OBJECT)],
});

export const allExams = atom({
  key: "all-exams",
  default: [],
  effects: [localStorageEffect(LOCAL_CONSTANT.ALL_EXAMS)],
});

export const allExamDepartments = atom({
  key: "all-exam-departments",
  default: [],
  effects: [localStorageEffect(LOCAL_CONSTANT.ALL_EXAM_DEPARTMENTS)],
});

export const selectedLanguage = atom({
  key: LOCAL_CONSTANT.SELECTED_LANGUAGE,
  default: { value: "en", label: "English" },
  effects: [localStorageEffect(LOCAL_CONSTANT.SELECTED_LANGUAGE)],
});

export const questionFilterLanguage = atom({
  key: LOCAL_CONSTANT.QUESTION_FILTER_LANGUAGE,
  default: { value: "en", label: "English" },
  // effects: [localStorageEffect(LOCAL_CONSTANT.QUESTION_FILTER_LANGUAGE)],
});

export const previousYearActiveTab = atom({
  key: "previousYearActiveTab",
  default: MODES.FULL_LENGTH,
});

export const subject = atom({
  key: "subject",
  default: "",
});

export const chapter = atom({
  key: "chapter",
  default: "",
});

export const topic = atom({
  key: "topic",
  default: "",
});

export const year = atom({
  key: "year",
  default: "",
});

export const mode = atom({
  key: "mode",
  default: "",
});

export const marks = atom({
  key: "marks",
  default: "",
});

export const set = atom({
  key: "set",
  default: "",
});

export const filteredQuestions = atom({
  key: "filteredQuestions",
  default: [],
});

export const courseSelectedSubject = atom({
  key: "courseSelectedSubject",
  default: "",
});

export const filteredForum = atom({
  key: "filteredForum",
  default: [],
});

export const filteredReports = atom({
  key: "filteredReports",
  default: [],
});
