import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { instance } from "../../services/https/inceptors";
import { endpoints } from "../../constants/Endpoints";
import { Input } from "../../components/inputs";
import { Button } from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { showToast } from "../../utilities/toast";
import CommonInput from "../../components/CommonInput";
import CommonResult from "../../components/CommonResult";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import { useRecoilValue } from "recoil";
import { authUser, selectedDepartmentObject } from "../../providers";
import ReactSelect from 'react-select';

const EditNotification = () => {
  const { id } = useParams();
  const { goBack } = useHistory();

  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const [name, setName] = useState({ [language.value]: '' });
  const [description, setDescription] = useState({ [language.value]: '' });
  const selectedDepart = useRecoilValue(selectedDepartmentObject);


  const [_id, set_id] = useState();
  const [imageLink, setImageLink] = useState("");
  const [publish, setPublish] = useState();
  const user = useRecoilValue(authUser);

  const getNotification = async () => {
    const res = await instance.get(
      `${endpoints.getSingleNotification}?_id=${id}`
    );
    setPrevious(res.data);
  };

  const setPrevious = (notification) => {
    set_id(notification._id);
    setPublish(notification.publish);
    setName(notification.name);
    setDescription(notification.description);
    setImageLink(notification.imageLink);
  };

  useEffect(() => {
    getNotification();
  }, []);

  const submit = async () => {
    try {
      await instance.put(endpoints.updateNotification, {
        ...{ _id, name, description, imageLink },
      });

      showToast({
        type: "success",
        message: "Successfully Edit Notification ..",
      });
      goBack();
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };



  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className='container'>
      <h2 className='space-heading text-center mb-2'>Edit Notification</h2>
      <div className='mb-4 px-3'>
        <h6 className='mb-1'>Select Language</h6>
        <ReactSelect
          onChange={onLanguageChange}
          options={selectedDepart?.languages?.map((lang) => {
            const label = Object.keys(LANGUAGES).find(
              (key) => LANGUAGES[key] === lang
            );
            return {
              value: lang,
              label: label.replace(/^./, label[0].toUpperCase()),
            };
          })}
          value={language}
          placeholder='Select Language'
          className='w-52'
        />
      </div>
      <div className="container create-screen">
        <div className="edit-section question-scroll">
          <Input
            className='!w-56'
            id='name'
            label='Name'
            value={name?.[language?.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <CommonInput
            directory={AWS_DIRECTORIES.NOTIFICATIONS}
            language={language.value}
            label="Description"
            value={description[language.value]}
            onChange={setDescription}
          />

          <Input
            id='link'
            label='Link'
            value={imageLink}
            onChange={setImageLink}
          />
        </div>
        <div className="result-section question-scroll">
          <div className="pb-5">
            <h6>Name:</h6>
            <p>{name?.[language.value]}</p>
          </div>

          <CommonResult label="Description:" text={description} />

          <div className="pt-5">
            <h6>Image Link:</h6>
            <p>{imageLink}</p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5 mb-5">
        {user?.role !== "master" && publish ? (
          <div className='flex items-center bg-[#ff000066] px-[6px] py-[10px] rounded-md cursor-pointer'>
            Published notification cannot be edited.
          </div>
        ) : null}

        {user.role !== "master" && !publish && (
          <Button className="w-25" onClick={submit}>
            Update
          </Button>
        )}

        {user?.role === "master" && (
          <Button className="w-25" onClick={submit}>
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditNotification;
