import "./style.scss";
import { useState } from "react";
import CommonInput from "../../components/CommonInput";
import { Input } from "../../components/inputs";
import CommonResult from "../../components/CommonResult/index";
import { instance } from "../../services/https/inceptors";
import { endpoints } from "../../constants/Endpoints";
import { showToast } from "../../utilities/toast";
import { useRecoilValue } from "recoil";
import {
  department as recoilDepart,
  exam as recoilExam,
  selectedDepartmentObject,
} from "../../providers/index";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/buttons";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from 'react-select';

const CreateNotification = () => {
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const [name, setName] = useState({ [language.value]: '' });
  const [description, setDescription] = useState({ [language.value]: '' });

  const [imageLink, setImageLink] = useState("");
  const department = useRecoilValue(recoilDepart).split("/")[1];
  const exam = useRecoilValue(recoilExam).split("/")[1];
  const selectedDepart = useRecoilValue(selectedDepartmentObject);


  const { goBack } = useHistory();

  const onSubmit = async () => {
    try {
      const response = await instance.post(endpoints.addNotification, {
        ...{ name, description, imageLink, exam, department },
      });

      if (response?.data) {
        showToast({
          type: "success",
          message: "Successfully Saved Notification!",
        });

        goBack();
      }
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };


  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className='container'>
      <h2 className='space-heading text-center mb-2'>Create Notifications</h2>
      <div className='mb-4 px-3'>
        <h6 className='mb-1'>Select Language</h6>
        <ReactSelect
          onChange={onLanguageChange}
          options={selectedDepart?.languages?.map((lang) => {
            const label = Object.keys(LANGUAGES).find(
              (key) => LANGUAGES[key] === lang
            );
            return {
              value: lang,
              label: label.replace(/^./, label[0].toUpperCase()),
            };
          })}
          value={language}
          placeholder='Select Language'
          className='w-52'
        />
      </div>

      <div className="container create-screen">
        <div className="edit-section question-scroll">
          <Input
            className='!w-56'
            id='name'
            label='Name'
            value={name?.[language?.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <CommonInput
            directory={AWS_DIRECTORIES.NOTIFICATIONS}
            language={language.value}
            label="Description"
            value={description[language.value]}
            onChange={setDescription}
          />

          <Input
            value={imageLink}
            onChange={setImageLink}
            label="Image Link"
            className="mt-5 w-100"
          />
        </div>
        <div className="result-section question-scroll">
          <div className="pb-5">
            <h6>Name:</h6>
            <p>{name?.[language.value]}</p>
          </div>

          <CommonResult label="Description:" text={description} />

          <div className="pt-5">
            <h6>Image Link:</h6>
            <p>{imageLink}</p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center w-100 mt-5 mb-5">
        <Button className="w-25" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateNotification;
