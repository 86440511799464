import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Submit } from '../../components/buttons';
import { Input, Select, Time } from '../../components/inputs';
import { Section } from '../../components/Section';
import { endpoints } from '../../constants/Endpoints';
import useData from '../../hooks/useData';
import { authUser, selectedDepartmentObject, selectedLanguage } from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { showToast } from '../../utilities/toast';
import { checkError } from '../../utilities/error';
import { Type } from '../../utilities/constants';
import CommonInput from '../../components/CommonInput';
import { AWS_DIRECTORIES, LANGUAGES } from '../../constants/Common';
import ReactSelect from 'react-select';
import DatePicker from '../../components/DatePicker';

const EditTestSeries = () => {
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useRecoilState(selectedLanguage);
  const [time, setTime] = useState(0);
  const [mode, setMode] = useState('');
  const [marks, setMarks] = useState(0);
  const [instructions, setInstructions] = useState();
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const [publish_, setPublish] = useState();
  const [toRenderSection, setToRenderSection] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [sectionChoice, setSectionChoice] = useState();
  const [subjects, setSubjects] = useState([]);
  const [prevSubjects, setPrevSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(authUser);
  const { goBack } = useHistory();
  const { id, department } = useParams();
  const { getSubjectByDepartment } = useData();


  useEffect(() => {
    const getData = async () => {
      const res = await instance.get(
        endpoints.getSingleTestSeries + `?id=${id}`
      );

      setPrevious(res.data);
    };

    getData();
  }, [id]);

  React.useEffect(() => {
    const fetchSubjects = async () => {
      const subjects = await getSubjectByDepartment(department);
      subjects.forEach((subj) => {
        subj.checked = prevSubjects.includes(subj._id);
      });

      setSubjects(subjects);
    };

    if (mode === 'subject') {
      fetchSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, mode]);

  const editCheckBoxes = (e) => {
    const clone = [...subjects];

    clone.forEach((subj) => {
      if (subj._id === e.target.value) {
        subj.checked = !subj.checked;
      }
    });
    setSubjects(clone);
  };

  function setPrevious(value) {
    setName(value?.name);
    setInstructions(value?.instructions);
    setDescription(value?.description);
    setTime(value?.time);
    setMarks(value?.marks);
    setMode(value?.mode);
    setToRenderSection(value?.sections);
    setPublish(value?.publish);
    setSectionChoice(value?.choice);
    setPrevSubjects(value?.subjects);
    value.sections.forEach((sec, i) => (sec.sectionId = 'section' + i));
    if (value.releaseDate) {
      setSelectedDate(new Date(value.releaseDate));
    }
  }

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: '',
      questions: [],
      sectionId: 'section' + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);
    let allSubjects = [];
    for (let i = 0; i <= subjects.length; i++) {
      if (formData.get('checkbox' + i)) {
        allSubjects.push(formData.get('checkbox' + i));
      }
    }

    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      if (returnFunc) {
        setLoading(false);
        throw returnFunc;
      }
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) {
      return;
    }

    const data = {
      _id: id,
      name,
      time,
      marks,
      mode,
      description,
      updatedBy: user?.userId,
      instructions,
      sections: sectionsClone,
      releaseDate: selectedDate?.getTime(),
      choice: sectionChoice,
      subjects: allSubjects,
    };

    try {
      await instance.put(endpoints.updateTestSeries, data);
      showToast({
        type: 'success',
        message: 'Successfully update the test series',
      });
      setLoading(false);
      goBack();
    } catch (err) {
      showToast({
        type: 'error',
        message: err?.response?.data.message,
      });
    }
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className='container mb-5'>
      <div className='flex-column justify-content-center'>
        <div className='d-flex flex-row justify-content-center fw-bold p-2 border'>
          <h2 className='mt-3 space-heading min-h-[2.5rem]'>{name?.[language.value]}</h2>
        </div>

        <form className='horizontal p-2 mt-2' onSubmit={onSubmit}>
          <div className='flex flex-wrap justify-normal gap-x-8 px-3'>
            <Input
              id='name'
              label='Name'
              value={name?.[language.value]}
              onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
            />

            <Input value={marks} label='Total marks' onChange={setMarks} />

            <Time value={time} label='Select Time' onChange={setTime} />

            <Select value={mode} label='Type' onChange={setMode}>
              <option value='full'>Full Length</option>
              <option value='subject'>Subject Wise</option>
            </Select>

            <DatePicker date={selectedDate} onChange={setSelectedDate} label='Release Date' />

            <Input
              name='choice'
              label='Choice'
              type='number'
              value={sectionChoice}
              onChange={(e) => {
                if (e > -1) {
                  setSectionChoice(e);
                }
              }}
            />

            <div className='mt-1'>
              <h6 className='mb-1'>Select Language</h6>
              <ReactSelect
                onChange={onLanguageChange}
                options={selectedDepart?.languages?.map((lang) => {
                  const label = Object.keys(LANGUAGES).find(
                    (key) => LANGUAGES[key] === lang
                  );
                  return {
                    value: lang,
                    label: label.replace(/^./, label[0].toUpperCase()),
                  };
                })}
                value={language}
                placeholder='Select Language'
                className='w-52'
              />
            </div>
          </div>

          {mode === 'subject' ? (
            <div className='select-subject-section'>
              <h5 className='subject-heading'>Select Subjects:</h5>
              <div className='test-series-subjects'>
                {subjects.map((subj, index) => {
                  return (
                    <div className='test-series-subject-input' key={index}>
                      <div className='form-check'>
                        <label
                          htmlFor={'checkbox' + (index + 1)}
                          className='form-check-label'>
                          {subj.title}
                        </label>
                        <input
                          onChange={editCheckBoxes}
                          checked={subj.checked}
                          className='form-check-input'
                          type='checkbox'
                          value={subj._id}
                          id={'checkbox' + (index + 1)}
                          name={'checkbox' + (index + 1)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className='mb-5 mx-4'>
            <CommonInput
              language={language.value}
              directory={AWS_DIRECTORIES.TEST_SERIES}
              label='General Instructions'
              value={instructions?.[language.value]}
              onChange={setInstructions}
            />
          </div>

          <div className='mb-3 mx-4'>
            <CommonInput
              language={language.value}
              directory={AWS_DIRECTORIES.TEST_SERIES}
              label='Description'
              value={description?.[language.value]}
              onChange={setDescription}
            />
          </div>

          {toRenderSection?.map((section, index) => {
            return (
              <Section
                language={language}
                type={Type.SERIES}
                key={index + section.sectionId}
                index={index}
                section={section}
                setToRenderSection={setToRenderSection}
                deleteSection={deleteSection}
              />
            );
          })}
          <div className='d-flex justify-content-between mt-3 '>
            <Button width='20' onClick={handleSection}>
              Add Section
            </Button>

            <div className='d-flex justify-content-end mt-3'>
              {user?.role !== 'master' && publish_ ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#ff000066',
                    padding: '6px 10px',
                    borderRadius: '5px',
                    cursor: 'not-allowed',
                  }}>
                  Published question cannot be edited
                </div>
              ) : null}

              {user.role !== 'master' && !publish_ && (
                <Submit
                  width='100'
                  disabled={
                    time === 0 ||
                    loading ||
                    !name ||
                    !marks ||
                    !instructions ||
                    !mode ||
                    !selectedDate ||
                    !toRenderSection.length
                  }
                />
              )}

              {user?.role === 'master' && (
                <Submit width='100' disabled={time === 0 || loading || !name[language.value] || !instructions[language.value]} />
              )}
            </div>
          </div>
        </form >
      </div >
    </div >
  );
};
const EditTestSeriesPage = () => {
  return (
    <>
      <div className='h-100 overflow-auto '>
        <EditTestSeries />
      </div>
    </>
  );
};

export default EditTestSeriesPage;
