import { useRef, useState } from 'react';
import { Equation } from '../buttons';
import { Image } from '../inputs';

const CommonInput = ({ value, onChange, label, language, directory }) => {
  const ref = useRef();
  const [showDemo, setDemo] = useState(false);

  const updateHeight = () => {
    const textArea = ref.current;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  function addImg(imgSrc) {
    onChange((prev) => ({
      ...prev,
      [language]:
        prev[language] +
        `<center><img src="${imgSrc}" height="150px" alt="question_img"/></center>`,
    }));
  }

  function onEquation(text) {
    const prev = value;
    const equation =
      prev.length === 0 || prev.endsWith(' ') ? text : ' ' + text;

    onChange((prev) => ({ ...prev, [language]: prev[language] + equation }));
  }

  return (
    <div className='mb-3 w-100'>
      <label htmlFor='solution' className='form-label'>
        {label}
      </label>

      {showDemo ? (
        <div
          dangerouslySetInnerHTML={{ __html: value }}
          className='overflow-auto h-fit border border-green-700 rounded-md p-3'
        />
      ) : (
        <div>
          <textarea
            id='solution'
            rows='5'
            className='form-control'
            ref={ref}
            value={value}
            onChange={(event) => {
              updateHeight();
              onChange((prev) => ({ ...prev, [language]: event.target.value }));
            }}
          />
        </div>
      )}

      <div className='d-flex justify-content-between mt-3'>
        <div className='d-flex gap-2'>
          {!showDemo && (
            <>
              <Image
                directory={directory}
                className='ms-3'
                onUpload={(value_) => addImg(value_)}
              />
              <Equation inputRef={ref} onEntered={onEquation} />
            </>
          )}
        </div>

        <div>
          <h6 onClick={() => setDemo(!showDemo)} className='c-pointer'>
            {showDemo ? 'Hide' : 'Show Demo'}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default CommonInput;
