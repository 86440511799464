import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { authUser, selectedDepartmentObject } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { checkError } from "../../utilities/error";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from 'react-select';

const EditDpp = () => {
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const [name, setName] = useState();
  const [time, setTime] = useState(0);
  const data = useData();

  const [marks, setMarks] = useState(0);
  const [instructions, setInstructions] = useState({});
  const [toRenderSection, setToRenderSection] = useState([]);
  const [sectionChoice, setSectionChoice] = useState();
  const [publish, setPublish] = useState();

  const [selectedSubject, setSelectedSubject] = useState();
  const [subjects, setSubjects] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState();
  const [topics, setTopics] = useState([]);

  const [selectedChapter, setSelectedChapter] = useState();
  const [chapters, setChapters] = useState([]);

  const { goBack } = useHistory();
  const { id, department } = useParams();
  const user = useRecoilValue(authUser);
  const { getSubjectByDepartment, getChaptersBySubject } = useData();

  const getData = async () => {
    const res = await instance.get(endpoints.getSingleDpp + `?_id=${id}`);
    setPrevious(res.data);
  };

  const fetchSubjects = async () => {
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
  };

  const fetchChapters = async () => {
    const res = await getChaptersBySubject(selectedSubject);
    setChapters(res);
  };

  const fetchTopics = async () => {
    const res = await data.getTopicsByChapter(selectedChapter);
    setTopics(res);
  };

  useEffect(() => {
    if (id) getData();
    if (department) fetchSubjects();
  }, [id, department]);

  useEffect(() => {
    if (selectedSubject) {
      fetchChapters();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedChapter) {
      fetchTopics();
    }
  }, [selectedChapter]);

  function setPrevious(value) {
    value.sections.forEach((sec, i) => (sec.sectionId = "section" + i));
    setSelectedSubject(value.subject);
    setSelectedChapter(value.chapter);
    setSelectedTopic(value.topic);
    setName(value.name);
    setTime(value.time);
    setInstructions(value.instructions);
    setMarks(value.marks);
    setToRenderSection(value.sections);
    setSectionChoice(value.choice);
    setPublish(value.publish);
  }

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    let allSubjects = [];
    for (let i = 0; i <= subjects.length; i++) {
      if (formData.get("checkbox" + i)) {
        allSubjects.push(formData.get("checkbox" + i));
      }
    }

    let questionIds = [];
    let returnFunc = false;

    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      _id: id,
      name,
      time,
      marks,
      instructions,
      updatedBy: user?.userId,
      sections: sectionsClone,
      choice: sectionChoice,
      subject: selectedSubject,
      chapter: selectedChapter,
      topic: selectedTopic,
    };

    try {
      await instance.put(endpoints.updateDpp, data);
      showToast({
        type: "success",
        message: "Successfully update the DPP",
      });
      goBack();
    } catch (err) {
      showToast({
        type: "error",
        message: err?.response?.data.message,
      });
    }
  };

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className="container mb-5">
      <div className="d-flex justify-content-center">
        <h2 style={{ color: `rgb(2, 79, 16)` }} className="mb-5 bolder">
          Create DPP
        </h2>
      </div>
      <form className="horizontal" onSubmit={onSubmit}>
        <div className="flex flex-wrap gap-x-4">
          <Input
            id='name'
            label='Name'
            value={name?.[language.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <Input value={marks} onChange={setMarks} label="Marks" />

          <Time value={time} onChange={setTime} label="Select Time" />

          <Select
            value={selectedSubject}
            onChange={setSelectedSubject}
            label="Subject"
          >
            {subjects?.map((item, index) => (
              <option value={item._id} key={index}>
                {item.title}
              </option>
            ))}
          </Select>

          <Select
            value={selectedChapter}
            onChange={setSelectedChapter}
            label="Chapter"
          >
            {chapters?.map((item, index) => (
              <option value={item._id} key={index}>
                {item.title}
              </option>
            ))}
          </Select>

          <Select
            value={selectedTopic}
            onChange={setSelectedTopic}
            label="Topic"
          >
            {topics?.map((item, index) => (
              <option value={item._id} key={index}>
                {item.title}
              </option>
            ))}
          </Select>

          <div className='mt-1'>
            <h6 className='mb-1'>Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>

        </div>
        <div className="mt-4 mb-3 mx-4">
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.DPP}
            label='General Instructions'
            value={instructions[language.value]}
            onChange={setInstructions}
          />
        </div>

        {toRenderSection.map((section, index) => {
          return (
            <Section
              language={language}
              key={index + section.sectionId}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}
        <div className="d-flex justify-content-start mt-3 ">
          <Button width="25" onClick={handleSection}>
            Add Section
          </Button>
        </div>

        <div className="d-flex justify-content-end mt-3">
          {user?.role !== "master" && publish ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#ff000066",
                padding: "6px 10px",
                borderRadius: "5px",
                cursor: "not-allowed",
              }}
            >
              Published DPP cannot be edited
            </div>
          ) : null}

          {user.role !== "master" && !publish && (
            <Submit
              width="25"
              disabled={
                time === 0 ||
                !marks ||
                !name[language.value] ||
                !toRenderSection.length ||
                !instructions[language.value]
              }
            />
          )}

          {user?.role === "master" && (
            <Submit
              width="25"
              disabled={
                time === 0 ||
                !name?.[language.value] ||
                !marks ||
                !instructions?.[language.value] ||
                !toRenderSection.length
              }
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default EditDpp;
