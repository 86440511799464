import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { PAYMENT_MODES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import {
  department as recoilDepart,
  exam as recoilExam,
} from "../../providers/index";

const CreatePayment = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mode, setMode] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const { goBack } = useHistory();
  const department = useRecoilValue(recoilDepart).split("/")[1];
  const exam = useRecoilValue(recoilExam).split("/")[1];

  const createPayment = async () => {
    try {
      const body = {
        title,
        description,
        mode,
        expiryDate,
        actualPrice,
        discount,
        department,
        exam,
      };
      await instance.post(endpoints.addPayment, body);
      goBack();
      showToast({ type: "success", message: "Successfully Saved Payment" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div>
      <h2 className="space-heading text-center">Create Payment</h2>
      <div className="w-40 m-auto">
        <Input
          className="mt-5"
          label="Title"
          value={title}
          onChange={setTitle}
        />

        <div className="mt-4 mb-4">
          <h6>Description</h6>
          <textarea
            rows="5"
            className="w-100 p-2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <Select label="Mode" value={mode} onChange={setMode}>
          {Object.keys(PAYMENT_MODES).map((key) => (
            <option value={key}> {PAYMENT_MODES[key]} </option>
          ))}
        </Select>

        <div className="mt-4">
          <div className="mb-2">Expiry Date</div>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div>
              <ReactDatePicker
                fixedHeight
                customInput={
                  <BsFillCalendar2Fill
                    cursor="pointer"
                    size={26}
                    color="green"
                  />
                }
                selected={expiryDate}
                minDate={Date.now()}
                onChange={(date) => setExpiryDate(date)}
              />
            </div>

            <div
              style={{
                width: "100%",
                cursor: "not-allowed",
                border: "1px solid gainsboro",
                padding: "5px 22px",
                borderRadius: "4px",
              }}
            >
              {expiryDate?.toString().length
                ? expiryDate?.toString()?.slice(0, 15)
                : "Date not selected"}
            </div>
          </div>
        </div>

        <Input
          className="mt-4"
          label="Actual Price"
          type="number"
          value={actualPrice}
          onChange={setActualPrice}
        />
        <Input
          className="mt-4"
          label="Discount"
          type="number"
          value={discount}
          onChange={setDiscount}
        />
      </div>

      <div className="text-center mb-5 mt-4">
        <Button
          disabled={!title || !description || !mode || !actualPrice}
          onClick={createPayment}
          className="w-25 mt-3"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreatePayment;
