import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { useRecoilValue } from "recoil";
import { Submit } from "../../components/buttons";
import { Images, Markup, Options, Result } from "../../components/displays";
import { Input, Select } from "../../components/inputs";
import {
  Answer,
  Choices,
  Length,
  Marks,
  Mode,
  Question,
  Solution,
} from "../../components/question";
import { LANGUAGES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import { ANSWER_MODES } from "../../constants/LocalConstants";
import useData from "../../hooks/useData";
import {
  allExamDepartments,
  selectedDepartmentObject,
  selectedLanguage,
} from "../../providers";
import { instance } from "../../services/https/inceptors";
import { useUser } from "../../store/application";
import { QuestionTypes, Type, YEARS } from "../../utilities/constants";
import { choiceLabel, years } from "../../utilities/helpers";
import { showToast } from "../../utilities/toast";

const QuestionEdit = () => {
  const user = useUser();
  const { state } = useLocation();
  const { goBack } = useHistory();

  const departments = useRecoilValue(allExamDepartments);
  const currentLanguage = useRecoilValue(selectedLanguage);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);

  const [language, setSelectedLanguage] = useState({ ...currentLanguage });

  const [text, setText] = useState("");
  const [mode, setMode] = useState(Mode.answer);
  const [answer, setAnswer] = useState("");
  const [negativeMarks, setNegativeMarks] = useState("");
  const [positiveMarks, setPositiveMarks] = useState("");
  const [choices, setChoices] = useState([]);
  const [images, setImages] = useState([]);
  const [solution, setSolution] = useState({ ...Solution.NODES });
  const [department, setDepartment] = useState("");
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState("");
  const [set, setSet] = useState("");
  const [chapter, setChapter] = useState("");
  const [topic, setTopic] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [difficultLevel, setDifficultLevel] = useState("");
  const [solutionImages, setSolutionImages] = useState([]);

  const [video, setVideo] = useState({});
  const dataHook = useData();

  useEffect(() => {
    setText(state?.question?.text);
    setMode(state.question.mode);
    setAnswer(state?.question?.answer);
    setSolutionImages(state.question.solution.images);
    setChoices(
      state?.question?.choices.map((choice, i) => ({ ...choice, id: i }))
    );
    setImages(state?.question?.images);
    setSolution(state?.question?.solution);
    setDepartment(state?.question?.department);
    setSubject(state?.question?.subject?._id);
    setVideo(state?.question?.video ?? {});
    setPositiveMarks(state?.question?.markingRule?.positive);
    setNegativeMarks(state?.question?.markingRule.negative);
    setDifficultLevel(state?.question?.level);

    if (state?.question?.chapter) {
      setChapter(state?.question?.chapter);
    }
    if (state?.question?.topic) {
      setTopic(state?.question?.topic);
    }

    setYear(state?.question?.year?.toString());

    if (state?.question?.set) {
      setSet(state?.question?.set.toString());
    }
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    const markingRule = {
      negative: negativeMarks,
      positive: positiveMarks,
    };
    const toSendChoice = [];

    choices.forEach((choice) => {
      if (typeof choice.text === "string") {
        toSendChoice.push({
          image: choice.image,
          answer: choice.answer,
          text: { [currentLanguage]: choice.text },
        });
      } else {
        toSendChoice.push(choice);
      }
    });

    const solutionClone = { ...solution };

    if (typeof solutionClone.text === "string") {
      const backupString = solutionClone.text;
      solutionClone.text = { [currentLanguage]: backupString, hn: "" };
    }

    solutionClone.images = solutionImages;

    const data = {
      _id: state.question._id,
      text,
      markingRule,
      user,
      answer,
      choices: toSendChoice,
      solution: solutionClone,
      images,
      year,
      mode,
      video,
      set,
      level: difficultLevel,
    };

    if (chapter) {
      data.chapter = chapter;
    }
    if (subject) {
      data.subject = subject;
    }
    if (department) {
      data.department = department;
    }
    if (topic) {
      data.topic = topic;
    }

    try {
      const res = await instance.put(endpoints.updateQuestion, data);
      showToast({
        type: "success",
        message: "Successfully edited the question.",
      });
      if (res) {
        goBack();
      }
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await dataHook.getSubjectByDepartment(department);
      setSubjects(res);
    };

    if (department) {
      fetchSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  useEffect(() => {
    const fetchChapter = async () => {
      const res = await dataHook.getChaptersBySubject(subject);
      setChapters(res);
    };

    if (subject) {
      fetchChapter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    const fetchTopics = async () => {
      const res = await dataHook.getTopicsByChapter(chapter);
      setTopics(res);
    };

    if (chapter) {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  function invalid() {
    let check =
      mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ ? false : true;
    let i = 0;

    if (QuestionTypes.MCQ || mode === QuestionTypes.MSQ) {
      choices.forEach((c) => {
        if (c.answer === true && mode === QuestionTypes.MCQ) {
          check = true;
        }

        if (c.answer === true && mode === QuestionTypes.MSQ) {
          i++;
          if (i > 0) {
            check = true;
          }
        }
      });
    }

    if (!department || !check) {
      return true;
    }
    return false;
  }

  console.log(choices);

  const onLanguageChange = useCallback((e) => {
    setSelectedLanguage(e);
    setAnswer((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
    setText((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
    setVideo((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
    setChoices((prev) => {
      return prev.map((choice) => {
        if (!choice.text[e.value]) {
          return { ...choice, text: { ...choice.text, [e.value]: "" } };
        } else return choice;
      });
    });
  }, []);

  return (
    <div className="container h-100 py-5">
      <h4 className="space-heading text-center -mt-12 mb-4">
        Edit Question Window
      </h4>

      <div className="row h-100">
        <div className="mb-4 flex items-center justify-normal gap-x-6 flex-wrap">
          <div className="-mt-3">
            <h6 className="mb-1">Select Question Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder="Select Language"
              className="w-52"
            />
          </div>
          <div className="!w-56">
            <Select
              label="Department"
              value={department}
              onChange={(e) => {
                setDepartment(e);
                setTopic("");
                setChapter("");
              }}
            >
              {departments?.map((department) => (
                <option key={department.code} value={department?._id}>
                  {department.title}
                </option>
              ))}
            </Select>
          </div>
          <div className="!w-56">
            <Select
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e)}
              vertical
            >
              {subjects.map((sub) => (
                <option key={sub.code} value={sub?._id}>
                  {sub.title}
                </option>
              ))}
            </Select>
          </div>

          <div className="!w-56">
            <Select
              label="Chapter"
              value={chapter}
              onChange={(e) => setChapter(e)}
              vertical
            >
              {chapters?.map((chapter) => (
                <option key={chapter.code} value={chapter._id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
          </div>

          <div className="!w-56">
            <Select label="Topic" value={topic} onChange={setTopic} vertical>
              {topics.map((topic) => (
                <option key={topic.code} value={topic._id}>
                  {topic.title}
                </option>
              ))}
            </Select>
          </div>

          <div className="!w-56">
            <Select
              label="level"
              value={difficultLevel}
              onChange={setDifficultLevel}
            >
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Hard">Hard</option>
            </Select>
          </div>

          <div className="!w-56">
            <Select label="Year" value={year} onChange={setYear}>
              {YEARS.map(({ name, value }) => (
                <option value={value}>{name}</option>
              ))}

              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}

              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </div>

          <div className="!w-56">
            <Select
              label="Mode"
              value={mode}
              onChange={(e) => setMode(e)}
              vertical
            >
              {ANSWER_MODES.map((mode) => {
                // if (mode.value === "mcq" || mode.value === "msq") {
                return <option value={mode.value}>{mode.label}</option>;
                // }
              })}
            </Select>
          </div>

          <div className="!w-56">
            {(year !== Type.SERIES || year !== Type.QUIZ) && (
              <Select label="Set" value={set} onChange={setSet}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
                <option value={16}>16</option>
                <option value={17}>17</option>
                <option value={18}>18</option>
                <option value={19}>19</option>
                <option value={20}>20</option>
                <option value={21}>21</option>
                <option value={22}>22</option>
                <option value={23}>23</option>
                <option value={24}>24</option>
                <option value={25}>25</option>
                <option value={26}>26</option>
                <option value={27}>27</option>
                <option value={28}>28</option>
                <option value={29}>29</option>
                <option value={30}>30</option>
                <option value={31}>31</option>
                <option value={32}>32</option>
                <option value={33}>33</option>
                <option value={34}>34</option>
                <option value={35}>35</option>
                <option value={36}>36</option>
                <option value={37}>37</option>
                <option value={38}>38</option>
                <option value={39}>39</option>
                <option value={40}>40</option>
                <option value={41}>41</option>
                <option value={42}>42</option>
                <option value={43}>43</option>
                <option value={44}>44</option>
                <option value={45}>45</option>
                <option value={46}>46</option>
                <option value={47}>47</option>
                <option value={48}>48</option>
                <option value={49}>49</option>
                <option value={50}>50</option>
              </Select>
            )}
          </div>
        </div>

        <div className="flex gap-x-5">
          <div className="h-100 w-1/2 overflow-auto">
            <h4 className="mb-2">Edit Question</h4>
            <div className="question-scroll">
              <form id="form" onSubmit={onSubmit}>
                <Question
                  value={text[language.value]}
                  onChange={setText}
                  language={language.value}
                />

                <div className="d-flex flex-row justify-content-between">
                  <Marks value={positiveMarks} onChange={setPositiveMarks} />
                  <Marks
                    value={negativeMarks}
                    name="Negative Marks"
                    onChange={setNegativeMarks}
                  />
                </div>
                {(mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ) && (
                  <Length
                    value={choices.length}
                    onChange={setChoices}
                    choices={choices}
                    language={language.value}
                  />
                )}
                {mode === QuestionTypes.ANSWER && (
                  <>
                    <label className="form-label">Answer</label>
                    <Answer
                      language={language.value}
                      value={answer[language.value]}
                      onChange={setAnswer}
                    />
                  </>
                )}

                {(mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ) && (
                  <>
                    <label className="form-label">Options</label>
                    <Choices
                      values={choices}
                      onChange={setChoices}
                      mode={mode}
                      language={language.value}
                    />
                  </>
                )}

                <Solution
                  language={language.value}
                  value={solution}
                  onChange={setSolution}
                />

                <Input
                  className="!w-56"
                  id="name"
                  label="Video"
                  value={video?.[language?.value]}
                  onChange={(e) =>
                    setVideo((prev) => ({ ...prev, [language.value]: e }))
                  }
                />
              </form>
            </div>
          </div>

          <div className="w-1/2 h-100">
            <h4 className="mb-2">Question:</h4>
            <div className="border rounded p-3 overflow-auto question-scroll">
              <div className="py-2">
                <Markup
                  latex={text[language.value]}
                  className={images?.length < 1 && choices.length < 1 && "mb-0"}
                />
              </div>

              {images?.length ? (
                <Images images={images} onChange={setImages} />
              ) : null}

              {text && (
                <>
                  <Options
                    language={language.value}
                    choices={choices}
                    onChange={setChoices}
                  />
                  <hr className="mt-3 mb-1" />

                  <div className="py-2">
                    <p>
                      <span className="font-semibold">Positive Marks: </span>
                      {positiveMarks}
                    </p>
                    <p>
                      <span className="font-semibold">Negative Marks:</span>{" "}
                      {negativeMarks}
                    </p>
                  </div>

                  <div>
                    {mode === "answer" && (
                      <>
                        <hr className="mb-3 mt-2" />
                        <Markup
                          label="Correct Answer:"
                          latex={answer[language.value]}
                        />
                        <hr className="mb-3 mt-3" />
                      </>
                    )}
                  </div>

                  <div>
                    {mode === QuestionTypes.MCQ &&
                      choices.map(
                        (choice, index) =>
                          choice.answer && (
                            <>
                              <hr className="mb-2" />
                              <p key={index}>
                                <span className="font-semibold">
                                  Correct Answer:
                                </span>{" "}
                                Choice {choiceLabel(index)}
                              </p>
                              <hr className="mt-2 mb-2" />
                            </>
                          )
                      )}
                  </div>

                  <div className="py-1">
                    <Result
                      solution={solution}
                      onChange={setSolution}
                      oid={state.id}
                      language={language.value}
                    />
                  </div>

                  {solutionImages?.length ? (
                    <Images
                      images={solution.images}
                      onChange={setSolutionImages}
                    />
                  ) : null}

                  {department && (
                    <div className="d-flex justify-content-center mt-3">
                      <Submit width="75" form="form" disabled={invalid()} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionEditPage = () => <QuestionEdit />;

export default QuestionEditPage;
