import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { authUser, selectedDepartmentObject } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { checkError } from "../../utilities/error";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from 'react-select';

const AddAssignment = () => {
  const [language, setSelectedLanguage] = useState({ value: 'en', label: 'English' });
  const [name, setName] = useState({ [language.value]: '' });
  const [instructions, setInstructions] = useState({ [language.value]: '' });
  const { department, exam } = useParams();
  const [toRenderSection, setToRenderSection] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();

  const [marks, setMarks] = useState("");
  const [time, setTime] = useState(0);
  const data = useData();
  const user = useRecoilValue(authUser);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);


  React.useEffect(() => {
    const getSubjects = async () => {
      const res = await data.getSubjectByDepartment(department);
      setSubjects(res);
    };

    if (department && exam) getSubjects();
  }, [department, exam]);

  const getChapter = async (e) => {
    setSelectedSubject(e);
    const res = await data.getChaptersBySubject(e);
    setChapters(res);
  };

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) { temp = true; }
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onSubmit = async (event) => {
    event.preventDefault();

    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      exam,
      department,
      name,
      postedBy: user.userId,
      time,
      instructions,
      marks,
      sections: sectionsClone,
      chapter: selectedChapter,
      subject: selectedSubject,
    };

    try {
      await instance.post(endpoints.addAssignemnt, data);
      showToast({
        type: "success",
        message: "Successfully Added Assignment..",
      });

      setMarks("");
      setTime(0);
      setName({ [language.value]: '' });
      setInstructions({ [language.value]: '' });

      setToRenderSection([]);
    } catch (err) {
      showToast({
        type: "error",
        message: "Assignment Cannot be added",
      });
    }
  };


  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };


  return (
    <>
      <div className="d-flex justify-content-center ">
        <h4 className="space-heading mb-5">Create Assignment</h4>
      </div>
      <form className="horizontal" onSubmit={onSubmit}>
        <div className="flex flex-wrap gap-x-8 items-center">
          <Input
            className='!w-56'
            id='name'
            label='DPP Name'
            value={name[language?.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <Select
            value={selectedSubject}
            onChange={getChapter}
            label="Subject"
          >
            {subjects.length > 0 &&
              subjects.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.title}
                </option>
              ))}
          </Select>

          <Select
            value={selectedChapter}
            onChange={setSelectedChapter}
            label="Chapter"
          >
            {chapters.length > 0 &&
              chapters.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.title}
                </option>
              ))}
          </Select>
          <Input value={marks} onChange={setMarks} label="Marks" />
          <Time value={time} onChange={setTime} label="Select Time" />
          <div className='-mt-1'>
            <h6 className='mb-1'>Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>
        </div>

        <div className="mt-4 mb-5 mx-4">

          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.ASSIGNMENT}
            label='General Instructions'
            value={instructions[language.value]}
            onChange={setInstructions}
          />

        </div>
        {toRenderSection.map((section, index) => {
          return (
            <Section
              language={language}
              key={index + section.sectionId}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}
        <div className="d-flex justify-content-start mt-3 ">
          <Button width="25" onClick={handleSection}>
            Add Section
          </Button>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Submit
            width="25"
            disabled={
              !name[language.value] ||
              time === 0 ||
              !marks ||
              !instructions[language.value] ||
              !toRenderSection.length
            }
          />
        </div>
      </form>
    </>
  );
};
const Assignment_add_page = () => {
  return (
    <div className="container mb-5 d-flex flex-column justify-content-center">
      <AddAssignment />
    </div>
  );
};

export default Assignment_add_page;
