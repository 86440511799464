import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CommonInput from "../../components/CommonInput";
import { Input } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import {
  department as recoilDepart,
  exam as recoilExam,
  selectedDepartmentObject,
} from "../../providers/index";
import CommonResult from "../../components/CommonResult";
import { Button } from "../../components/buttons";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from 'react-select';

const CreateYearCutoff = () => {
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const [name, setName] = useState({ [language.value]: '' });
  const [description, setDescription] = useState({ [language.value]: '' });
  const { goBack } = useHistory();
  const department = useRecoilValue(recoilDepart).split("/")[1];
  const exam = useRecoilValue(recoilExam).split("/")[1];

  const selectedDepart = useRecoilValue(selectedDepartmentObject);


  const onSubmit = async () => {
    try {
      await instance.post(endpoints.addYearCutOff, {
        ...{ name, description, exam, department },
      });

      showToast({
        type: "success",
        message: "Successfully Saved Year Cut Off!",
      });

      goBack();
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });
  };

  return (
    <div className='container'>
      <h1 className='space-heading text-center mb-4'>Create Year Cutoff</h1>
      <div className='mb-4 px-3'>
        <h6 className='mb-1'>Select Language</h6>
        <ReactSelect
          onChange={onLanguageChange}
          options={selectedDepart?.languages?.map((lang) => {
            const label = Object.keys(LANGUAGES).find(
              (key) => LANGUAGES[key] === lang
            );
            return {
              value: lang,
              label: label.replace(/^./, label[0].toUpperCase()),
            };
          })}
          value={language}
          placeholder='Select Language'
          className='w-52'
        />
      </div>
      <div className="container create-screen">
        <div className="edit-section question-scroll">
          <Input
            className='!w-56'
            id='name'
            label='DPP Name'
            value={name?.[language?.value]}
            onChange={e => setName(prev => ({ ...prev, [language.value]: e }))}
          />

          <CommonInput
            directory={AWS_DIRECTORIES.YEAR_CUTOFF}
            language={language.value}
            label="Description"
            value={description[language.value]}
            onChange={setDescription}
          />
        </div>
        <div className="result-section question-scroll">
          <div className="pb-5">
            <h6>Name:</h6>
            <p>{name?.[language.value]}</p>
          </div>

          <CommonResult language={language.value} label="Description:" text={description} />
        </div>
      </div>

      <div className="d-flex justify-content-center w-100 mt-5 mb-5">
        <Button className="w-25" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateYearCutoff;
