import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import AvailableContentLanguage from "../../components/AvailableContentLanguage";
import { Button } from "../../components/buttons";
import CommonResult from "../../components/CommonResult/index";
import { Markup } from "../../components/displays";
import { endpoints } from "../../constants/Endpoints";
import { authUser, selectedLanguage } from "../../providers";
import { instance } from "../../services/https/inceptors";
import { Admin } from "../../utilities/constants";
import { showToast } from "../../utilities/toast";
import "./style.scss";

const ViewContent = () => {
  const [content, setContent] = useState("");
  const { push, goBack } = useHistory();
  const { contentId } = useParams();
  const user = useRecoilValue(authUser);
  const [language, setLanguage] = useRecoilState(selectedLanguage);

  const getContent = async () => {
    const res = await instance.get(
      `${endpoints.getSingleContent}?_id=${contentId}`
    );

    setContent(res.data);
  };

  useEffect(() => {
    getContent();
  }, [contentId]);

  const deleteContent = async () => {
    try {
      if (window.confirm("Are you sure want to delete this content?")) {
        await instance.delete(`${endpoints.deleteContent}?_id=${contentId}`);
        goBack();
        showToast({
          type: "success",
          message: "Successfully deleted the content!",
        });
      }
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const reviewContent = async () => {
    try {
      const res = await instance.put(
        `${endpoints.toggleContentReview}?_id=${contentId}&reviewedBy=${user.userId}`
      );

      const clone = { ...content };
      clone.review = res.data.review;
      setContent(clone);
      showToast({
        type: "success",
        message: clone.review ? "Content Reviewed" : "Content Unreviewed",
      });
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const publishContent = async () => {
    try {
      const res = await instance.put(
        `${endpoints.toggleContentPublish}?_id=${contentId}`
      );

      const clone = { ...content };
      clone.publish = res?.data?.publish;
      setContent(clone);
      showToast({
        type: "success",
        message: clone.publish ? "Content Published" : "Content Unpublished",
      });
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  return (
    <div className="container">
      <div className="content-screen relative">
        <div className="relative">
          <div className="absolute !right-5">
            <AvailableContentLanguage
              setCurrentLanguage={setLanguage}
              currentLanguage={language?.value}
              allLanguageContent={content?.contents?.[0]?.title}
            />
          </div>

          {content?.contents?.map((cont) => (
            <div className="mt-5">
              <div className="">
                <h4 className="!text-lg uppercase">Title</h4>
                <Markup latex={cont?.title?.[language.value]} />
              </div>
              <div className="mt-2">
                <h4 className="!text-lg uppercase">Content</h4>
                <CommonResult language={language.value} text={cont?.content} />
              </div>
            </div>
          ))}
        </div>

        <div>
          <div className="d-flex justify-content-center gap-4 mt-5">
            <Button
              className="w-30"
              onClick={deleteContent}
              style={{ background: "red" }}
            >
              Delete
            </Button>

            <Button
              className="w-30"
              style={{ background: content.review ? "#00a600" : "#024f10" }}
              onClick={reviewContent}
            >
              {content.review ? "Unreview" : "Review"}{" "}
            </Button>
          </div>
          <div className="d-flex gap-4 justify-content-center mt-3">
            {user.role === Admin.MASTER && (
              <Button
                className="w-30"
                style={{ background: content.publish ? "#00a600" : "#024f10" }}
                onClick={publishContent}
              >
                {content.publish ? "Unpublish" : "Publish"}
              </Button>
            )}

            <Button
              className="w-30"
              style={{ background: "yellow", color: "black" }}
              onClick={() => push(`/edit-content/${contentId}`)}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContent;
